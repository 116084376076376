import React from "react";
import styled from "styled-components";
import { useForm,  ValidationError } from "@formspree/react";

const Form = styled.form`
    label,
    input {
        display: block;
        width: 100%;
        margin-bottom: 15px;
    }

    textarea {
        width: 100%;
        height: 100px;
    }

    input {
        border: none;
        border-bottom: 1px solid #393939;
        padding: 16px;
    }

    button {
        background-color: #fff;
        color: #000;
        outline: none;
        border: 1px solid #393939;
        padding: 10px 20px;
        display: block;
        margin-left: auto;
    }

    button:hover {
        background-color: #393939;
        color: #fff;
    }
`

export default function ContactForm() {
    const [state, handleSubmit] = useForm(process.env.GATSBY_FORM);

    if (state.succeeded) {
        return <p>Thank you for your message!</p>
    }

    return (
            <Form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input id="name" type="text" name="name" />
                <ValidationError prefix="Name" field="name" errors={state.errors} />
                
                <label htmlFor="email">Email</label>
                <input id="email" type="email" name="email" />
                <ValidationError prefix="Email" field="email" errors={state.errors} />

                <label htmlFor="subject">Subject</label>
                <input id="subject" type="text" name="subject" />
                <ValidationError prefix="Subject" field="subject" errors={state.errors} />

                <label htmlFor="message">Message</label>
                <textarea id="message" name="message"></textarea>
                <ValidationError prefix="Message" field="message" errors={state.errors} />

                <button type="submit" disabled={state.submitting}>
                    Submit
                </button>
                <ValidationError errors={state.errors} />
            </Form>
    )
}