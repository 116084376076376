import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";
import ContactForm from "../components/Form";
import SEO from "../components/SEO";

const ContactContainer = styled.div`
    padding: 2rem;
`;

const ContactFlexContainer = styled.div`
    display: flex;
    padding: 2rem;
    
    form,
    div {
        flex: 1;
    }

    div a {
        text-decoration: underline;
        font-weight: bold;
    }

    div p {
        width: 50%;
        margin: auto;
    }
    
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding-bottom: 10vh;
        padding: 0 0 8rem;

        form {
            margin-bottom: 3rem;
        }

        div {
            margin-bottom: 20px;
            p {
                width: 100%;
            }
        }
    }
`

export default function ContactPage({ data }) {
    const [{ _rawText }] = data.sanityPages.pageBuilder;
    return (
        <ContactContainer>
         <SEO
            title="Contact"
            keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
            />
            <h2>{data.sanityPages.title}</h2>
            <ContactFlexContainer>
                <ContactForm />
                <div>
                    <PortableText value={_rawText} />
                </div>
            </ContactFlexContainer>
        </ContactContainer>
    )
}

export const query = graphql`
   query ContactPage {
    sanityPages(title: {eq: "Contact"}) {
        title
        pageBuilder {
          ... on SanityTextContent {
            _key
            _type
            _rawText
          }
        }
      }
    }
`